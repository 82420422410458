.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  padding: 16px;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    h1 {
      font-size: 16px;
    }
  }

  footer {
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 26px 0;
    text-align: center;
    img {
      margin-bottom: 8px;
    }
  }

  .inner {
    width: 100%;
    max-width: 580px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      white-space: wrap;
      font-size: 12px;
      text-overflow: ellipsis;
    }

    p.error {
      color: red;
    }

    .paying {
      margin: 16px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-bottom: 20px;
      }
    }

    button {
      border-radius: 6px;
      border: none;
      background-color: #0077ff;
      padding: 10px 28px;
      color: white;
      font-weight: bold;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
